<template>
	<div class="personalLayout container">
		<!-- 面包屑 -->
		<div class="breadcrumb">
			<p>个人中心</p>
			<i class="el-icon-arrow-right"></i>
			<p>账户安全</p>
		</div>
		<!-- 侧边栏导航 -->
		<div class="flex-box">
			<div class="slide-tab-user">
				<div class="title">个人中心</div>
				<ul>
					<li class="active" @click="onTabItem"><i class="el-icon-lock"></i>账户安全</li>
				</ul>
			</div>
			<!-- 右边 -->
			<div class="sifa-cont-right">
				<!-- 账户信息 -->
				<div class="account-info" v-if="showInfo">
					<div class="title">账户信息</div>
					<div class="user-info">
						<div class="individu_box">个人信息</div>				
						<div class="info-wrap">
							<div class="infolist">
								<label class="subinfo-label">姓名：</label>
								<span class="subinfo-value">{{userInfo.name}}</span>
								<span class="subinfo-tip" @click="onAutonym">立即实名</span>
								<span>（实名认证通过后，才可对标的进行报名参拍）</span>
							</div>
							<div class="infolist">
								<label class="subinfo-label">手机号：</label>
								<span class="subinfo-value">{{userInfo.mobile | mobile}}</span>
								<!-- <span class="subinfo-tip">修改</span> -->
								<span>（手机号可作为登录账号，请牢记～）</span>
							</div>
						</div>
					</div>
					<div class="account-security">
						<div class="individu_box">安全服务</div>	
						<div class="security-wrap">
							<div class="securitylist">
								<label class="security-label">实名认证</label>
								<span class="security-tip">用于提升账号的安全性和信任级别。实名认证通过后，方可进行报名参拍。</span>
								<span class="security-btn J-auth-btn" @click="onAutonym">立即实名</span>
							</div>
						</div>
					</div>
				</div>
				
				<!-- 实名认证 -->
				<attestation v-else></attestation>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import attestation from '../attestation/attestation.vue'
	// import helpList from '@/components/help/helpList.vue'
	// import helpDetails from '@/components/help/helpDetails.vue'
	export default{
		data(){
			return{
				userInfo: null,
				showInfo: true
			}
		},
		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
			let options = this.$route.query.showInfo				
			console.log(options)
			if(options == 1){
				console.log(11)
				this.showInfo = false
			}
		},
		components: {
			attestation,
			// helpList,
			// helpDetails
		},
		methods:{
			// 点击回到主页
			onTabItem(){
				this.showInfo = true
			},		
			// 点击实名认证
			onAutonym(){
				this.showInfo = false
			}
		},
		filters:{
			mobile(value){
				let start = value.slice(0, 3)
				let end = value.slice(-4)
				return `${start}****${end}`
			}
		}
	}
</script>

<style lang="less" scoped>
	.personalLayout{
		margin-bottom: 30px;
		.breadcrumb{
			display: flex;
			align-items: center;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
			i{
				margin: 0 5px;
			}
		}
		.flex-box{
			display: flex;
			.title{
				height: 36px;
				padding-left: 20px;
				font-size: 16px;
				background: #e9e9e9;
				font-weight: 500;
				line-height: 36px;
			}
		}
		.slide-tab-user{
			width: 180px;
			background: #fff;
			ul{
				background-color: #fff;
				li {
					display: flex;
					align-items: center;
				    color: #333;
				    font-size: 16px;
				    padding-left: 30px;
				    cursor: pointer;
				    font-weight: 500;
				    height: 20px;
					line-height: 20px;
					margin: 20px 0;
				    border-left: 2px solid transparent;
					&.active {
					    color: #d6363b;
					    border-left: 2px solid #d6363b;
						i{
							color: #d6363b;
						}
					}
					i{
						margin-right: 10px;
						font-size: 20px;
						color: #d7d7d7;
					}
				}
			}
		}
		.sifa-cont-right{
			width: 1000px;
			background: #fff;
			margin-left: 20px;
			box-sizing: border-box;
			.individu_box{
				padding: 20px 20px 0 40px;
				font-size: 16px;
				color: #333;
			}
			.info-wrap {
			    padding: 20px 0;
			    border-bottom: 1px solid #d7d7d7;
				.infolist {
				    padding: 10px 0;
				    font-size: 12px;
				    color: #666;
				    line-height: 16px;
					.subinfo-label {
					    width: 120px;
					    display: inline-block;
					    vertical-align: top;
					    text-align: right;
					    font-size: 14px;
					}
					span {
					    margin-left: 10px;
					}
					.subinfo-value {
					    font-size: 14px;
					    color: #333;
					}
					.subinfo-tip {
					    color: #d6363b;
					    font-size: 12px;
						cursor: pointer;
					}
				}
			}
			.security-wrap {
			    padding: 5px 30px 1px;
				.securitylist {
				    background: url(../../assets/img/pay_succ.png) 40px center no-repeat;
				    line-height: 70px;
				    // border-bottom: 1px dotted #d7d7d7;
				    padding-left: 100px;
				    font-size: 12px;
					.security-label {
					    font-size: 14px;
					    color: #333;
					}
					.security-tip {
					    color: #666;
					    margin-left: 30px;
					}
					.security-btn {
					    float: right;
					    margin-right: 164px;
					    color: #d6363b;
					    width: 50px;    
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
